import React from 'react';
import styled, { css } from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Title, Button, Flex } from '../Ui';
import Text from '../Ui/Text';
import { Link } from 'react-router-dom';
import { Js } from 'styled-icons/fa-brands';

const Container = styled.div`
  border: ${props => props.borderWidth || '1px'} solid ${props => props.border || 'transparent'};
  background-color: ${props => props.bgColor || ''};
  border-radius: ${props => props.radius || props.theme.border_radius_generale_grande};
  width: 100%;
  margin-bottom: ${props => props.marginBottom || '25px'};
  position: relative;
  overflow: hidden;
  text-align: center;
  ${respondTo.sm`
    width:${({ width }) => (+width === 100 ? '100%' : 'calc(' + width + '% - 1%)')};
  `}

  button {
    display: inline-block;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    padding: 0 25px;
    /* background-color: #64b52c; */
    border-radius: ${({ theme }) => theme.border_radius_generale_piccolo};

    transition: all 0.3s;
    height: 50px;
    font-size: 16px;
    font-weight: 700;
    /* text-transform: uppercase; */
    border: none;
    line-height: 1;
    gap: 5px;
    margin: 15px;

    &:hover {
      opacity: 0.9;
    }
    a {
      color: #fff;
    }
  }
  /* button:nth-child(1) {
    background-color: #ff0;
  } */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .link {
    display: block;
    position: static;
    margin: 0 auto;
    margin-top: auto;

    button {
      padding: 0 70px;
      min-width: 230px;
    }
    ${respondTo.sm`

     ${({ position }) =>
       position
         ? css`
             position: absolute;
             left: 50%;
             bottom: 30px;
             transform: translateX(-50%);
           `
         : css`
             position: static;
           `}
`}
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${({ bg }) =>
    bg &&
    css`
      ${({ cancelMobile }) =>
        cancelMobile
          ? css`
              position: absolute;
              top: 0;
              left: 0;
              border: none;
            `
          : css`
              position: static;
              border: 1px solid ${({ theme }) => theme.colore_bordo_box};
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border-top: none;
            `}
      ${respondTo.sm`
      position: absolute;
      top: 0;
      left: 0;
      border:none;
`}
    `}

  width: 100%;
  height: 100%;
  padding: ${props => props.padding || '40px'};
`;

function Notification({ bg, data, notif, promo, width }) {
  const style = notif && JSON.parse(notif?.style);
  const titlePromo = notif?.i18l.title ? notif?.i18l.title : notif?.promotion?.i18l?.title;
  const contentPromo = notif?.i18l.content
    ? notif?.i18l.content
    : notif?.promotion?.i18l?.description;
  const valueBtn = notif?.i18l.extra ? notif?.i18l.extra : 'PARTECIPA';
  const ctaUrl = notif?.cta_url?.split(';');
  const ctaLabel = notif?.cta_label?.split(';');
  return (
    <Container
      data={data}
      bg={bg}
      bgColor={notif?.background_color}
      border={style?.border}
      radius={style?.radius}
      borderWidth={style?.borderWidth}
      marginBottom={style?.marginBottom}
      width={width}
      position={Boolean(notif?.img)}
      btn={notif?.cta_url}
    >
      {notif?.img && <img src={notif?.img} alt='' />}
      <Content bg={bg} padding={style?.padding}>
        <Text
          type='title'
          bold
          style={{ color: notif?.text_color }}
          align={style?.align || 'center'}
          width={style?.width}
        >
          {promo ? titlePromo : notif?.i18l.title}
        </Text>
        <Text as='p' width={style?.width} color={notif?.text_color} size={14}>
          {promo ? contentPromo : notif?.i18l.content}
        </Text>
        {promo && (
          <Link to={`/promo/${notif.promotion.slug}`} className='link'>
            <Button active style={{ margin: '0 auto' }}>
              {valueBtn}
            </Button>
          </Link>
        )}
        {Boolean(notif?.show_cta) && (
          <Flex gap='30px' justify='center' className='link'>
            {ctaUrl.map((item, i) => (
              <a href={item}>
                <Button active={i === 0} secondary={i !== 0}>
                  {ctaLabel[i]}
                </Button>
              </a>
            ))}
          </Flex>
        )}
      </Content>
    </Container>
  );
}

export default Notification;
