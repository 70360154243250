import React, { Suspense, useEffect } from 'react';
import { Button, Input, Loader, Modal } from '../../components/Ui';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SessionExpired from '../../components/SessionExpired';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import useQuery from '../../hooks/useQuery';
import { signinSuccess } from '../../store/actions/auth';
import useForm from '../../hooks/useForm';
import { AcceptRulesForm, UserActionForm } from '../../formsConfig/formsConfig';
import { useTranslation } from 'react-i18next';
import GenericScripts from '../../components/GenericScripts/GenericScripts';

const MainContent = styled.div`
  flex: 1 0 auto;
  // max-width: 1511px;
  width: 100%;
  margin: 0 auto;
  //padding: 0px 5px 0 5px;

  ${respondTo.sm`
	
    padding: ${props => props.theme.header_mobile_height + 30}px 0 0 0;
    padding-top: 0;
	`}
`;

const BaseLayout = props => {
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    footercontent,
    setUserInfo,
    getUserInfo,
    appConfig: { i18l },
    fields,
    otherLoginLoading,
    otherLoginSuccess,
    codeApp,
  } = props;

  let lazy = null;
  const queryApp = useSelector(state => state.app.appQuery);
  const { action_required, action_required_fields } = useSelector(state => state.user.user || {});
  const script = useSelector(state => state.app?.config?.i18l);
  const [t] = useTranslation();
  /* for modal */
  let objFields = {};
  fields.forEach(item => {
    objFields[item.name] = item;
  });

  /* rules modal */

  const accepteRulesForm = AcceptRulesForm({ obj: objFields });

  //const accetta_regolamento = JSON.parse(localStorage.getItem('accetta_regolamento'));

  let requiredFields = {};

  (action_required_fields || []).forEach(item => {
    if (typeof item === 'object') {
      requiredFields[item?.name] = item;
    } else {
      requiredFields[item] = { name: item, required: true };
    }
  });

  const userActionForm = UserActionForm(userInfo || {}, objFields, codeApp, requiredFields);

  const userActionFormfiltered = Object.keys(userActionForm)
    .filter(key =>
      [
        ...(action_required_fields?.map(item => {
          if (typeof item === 'string') {
            return item;
          } else {
            return item.name;
          }
        }) || []),
        'email',
        'firstname',
        'lastname',
      ]?.find(item => item === key),
    )
    .reduce((obj, key) => {
      obj[key] = userActionForm[key];
      return obj;
    }, {});

  const updateUserAction = () => {
    const data = {};

    for (const property in formData) {
      data[property] =
        property !== 'dob' ? formData[property].value : formData[property].valuetosend;
    }
    let fieldsData;
    let dataToSend;
    if (userInfo?.accetta_regolamento) {
      fieldsData = Object.keys(data)
        .filter(key =>
          [
            ...(action_required_fields?.map(item => {
              if (typeof item === 'string') {
                return item;
              } else {
                return item.name;
              }
            }) || []),
            'email',
            'firstname',
            'lastname',
          ]?.find(item => item === key),
        )
        .reduce((obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {});
      dataToSend = {
        ...userInfo,

        ...fieldsData,
        repeatemail: fieldsData.email,
      };
    } else {
      fieldsData = Object.keys(data).reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
      dataToSend = {
        fromapp: 'true',
        fromapp_fields: {
          ...fieldsData,
        },
      };
    }
    setUserInfo(dataToSend).then(() => getUserInfo());
  };
  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    startDate,
    datePickerHandler,
    countries,
    districts,
    cities,
    zipcode,
  } = useForm(
    updateUserAction,
    userInfo?.accetta_regolamento ? userActionFormfiltered : accepteRulesForm,
    [],
    null,
    null,
    null,
    userInfo,
  );

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        changedDatePicker={datePickerHandler}
        label={inp.label}
        startdate={startDate}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        radioInputs={inp.inputs}
        {...inp}
      />
    );
  });

  /* for modal */

  const Content = (
    <>
      <MainContent>
        {!queryApp && (
          <Header
            isLogged={isLogged}
            logo={logo}
            toggleSidebar={toggleSidebar}
            showSidebar={showSidebar}
            logout={logout}
            bootApp={bootApp}
            langs={langs}
            oauths={oauths}
            ownLogin={ownLogin}
            multipromo={multipromo}
            oauthInfo={oauthInfo}
            userInfo={userInfo}
            oauth_profile_url={oauth_profile_url}
            i18l={i18l}
          />
        )}

        <main style={{ marginTop: '15px' }}>
          <C isLogged={isLogged} />
        </main>
        {!queryApp && <GenericScripts scripts={script?.gdpr?.extra} />}
        {!queryApp && <Footer footercontent={footercontent} />}
      </MainContent>

      <Modal show={action_required} hideClode>
        {inputs}
        <Button active onClick={formSubmitHandler} style={{ margin: '0 auto' }}>
          Accetta
        </Button>
      </Modal>
      <Modal show={userInfo && !userInfo?.accetta_regolamento} hideClode>
        {inputs}
        <Button active onClick={formSubmitHandler} style={{ margin: '0 auto' }}>
          Ok
        </Button>
      </Modal>
    </>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return (
    <React.Fragment>
      {Comp}
      <SessionExpired
        isExpired={isExpired && !otherLoginLoading && !otherLoginSuccess}
        queryApp={queryApp}
      />
    </React.Fragment>
  );
};

BaseLayout.defaultProps = {
  type: 'base',
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    logoinverted: state.app.config.logoinverted,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    otherLoginLoading: state.error.otherLoginLoading,
    otherLoginSuccess: state.error.otherLoginSuccess,
    error404: state.promo.error404,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
    appConfig: state.app.config,
    fields: state.app.config.ownlogin.fields,
    codeApp: state.app.config.code,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: () => dispatch(actionCreators.logout()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
    setUserInfo: data => dispatch(actionCreators.setUserInfo(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
