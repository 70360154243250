import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { registrationform } from '../../formsConfig/formsConfig';
import { Button, SmallLink, Input, Loader, Modal, Typography, Flex, Text } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import RegisterFormSuccess from './RegisterFormSuccess';
import useForm from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { respondTo } from '../../theme/mixin';
import AuthContentWrapper from '../AuthContentWrapper/AuthContentWrapper';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { showAuthForm } from '../../store/actions/auth';
const Container = styled.div`
  label {
    color: ${({ theme }) => theme.colore_testo_box};
    a {
      color: ${({ theme }) => theme.colore_link};
    }
  }
  /*   label input:checked + .checkmark {
    background-color: ${({ theme }) => theme.default_color};
  }
  input[type='checkbox']:checked + label::before {
    background-color: ${({ theme }) => theme.default_color};
  } */
`;
const CustomButton = styled(Button)`
  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const RegisterForm = props => {
  const {
    signUpInit,
    loadingSignup,
    signup,
    signupSuccessMessage,
    clearError,
    error,
    lang,
    own_login_adult,
    fields,
    code: appCode,
    extrafields,
    gdpr,
    codefield: { label, visible, required, regex },
    notes,
  } = props;

  const history = useHistory();

  const [step, setStep] = useState(1);
  const [clickDisabled, setClickDisabled] = useState(false);

  let code = {};
  if (visible) {
    code = {
      code: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'code',
          id: 'code',
          placeholder: '',
        },
        label: `${label}`,
        value: '',
        validation: {
          isCustomCode: regex ? true : false,
          regex: regex,
          required: required,
          touched: false,
        },
        valid: !required,
        labelColorSecondary: true,
      },
    };
  }
  let objFields = {};
  fields.forEach(item => {
    objFields[item.name] = item;
  });

  const [showModal, setShowModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [showHint, setShowHint] = useState();
  const [t] = useTranslation();
  const inputRef = useRef();
  const pdv = useSelector(state => state.app.config.stores);
  const registerForm = registrationform(
    fields.find(f => f.name === 'email')?.label || t('auth.email'),
    fields.find(f => f.name === 'firstname')?.label || t('auth.firstname'),
    fields.find(f => f.name === 'lastname')?.label || t('auth.lastname'),
    fields.find(f => f.name === 'username')?.label || t('auth.username'),
    fields.find(f => f.name === 'password')?.label || t('auth.password'),
    fields.find(f => f.name === 'repeatpassword')?.label || t('auth.repeatpassword'),
    fields.find(f => f.name === 'dateofbirth')?.label || t('auth.dateofbirth'),
    fields.find(f => f.name === 'sex')?.label || t('auth.sex'),
    t('auth.male'),
    t('auth.female'),
    t('auth.pswerror'),
    t('auth.pswconfirmerror'),
    inputRef,
    gdpr,
    t('gdpr.accept'),
    t('gdpr.reject'),
    objFields,
    appCode,
  );

  const registerFormfiltered = Object.keys(registerForm)
    .filter(key => fields.find(item => item.name === key))
    .reduce((obj, key) => {
      obj[key] = registerForm[key];
      return obj;
    }, {});

  const finalForm = { ...registerFormfiltered, ...code };

  const register = () => {
    const data = {};

    if (visible) {
      fields.push('code');
    }

    for (const property in formData) {
      data[property] =
        property !== 'dob' ? formData[property].value : formData[property].valuetosend;
    }

    const fieldsData = Object.keys(data)
      .filter(key => fields.find(item => item.name === key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const otherFields = Object.keys(data)
      .filter(key => !fields.find(item => item.name === key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const dataToSend = {
      ...fieldsData,
      cluster_name: formData['institution_type']?.cluster_name,
    };
    !loadingSignup && signUpInit(dataToSend);
    setClickDisabled(true);
  };

  const modalHandler = () => {
    setShowModal(!showModal);
    clearError();
  };
  const signupModalHandler = () => {
    setSignupModal(prev => !prev);
    history.push('/auth/login');
  };

  useEffect(() => {
    if (error !== null) {
      if (error) {
        setShowModal(true);
      }
    }
    return () => {};
  }, [error]);
  console.log({ loadingSignup });
  useEffect(() => {
    if (signup) {
      setSignupModal(true);
    }
  }, [signup]);
  let datePickerNames = [];

  for (var prop in finalForm) {
    if (finalForm[prop].elementType === 'datepicker') {
      datePickerNames.push(finalForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    countries,
    districts,
    cities,
    zipcode,
    formIsValid,
    institutions,
    setFormData,
  } = useForm(register, finalForm, datePickerNames, null, null, null, null, setScroll, false);
  console.log({ formData });
  useEffect(() => {
    if (!formIsValid) {
      window.scrollTo(0, 0);
    }
  }, [formIsValid, scroll]);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  //for hint
  const clickHintHandler = hint => {
    setShowHint(hint);
  };
  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation?.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        own_login_adult={own_login_adult}
        radioInputs={inp.inputs}
        districts={districts}
        countries={countries}
        cities={cities}
        zipcode={zipcode}
        pdv={pdv}
        institutions={institutions}
        clickHintHandler={clickHintHandler}
        addressTypes={[]}
        form={formData}
        setForm={setFormData}
        {...inp}
      />
    );
  });

  return (
    <Container>
      <form onSubmit={formSubmitHandler}>
        <AuthContentWrapper title='Compila con i tuoi dati.' navActive='register'>
          <InputsWrapper>
            <div>{inputs[0]}</div>
            {formData['institution_type']?.cluster_name === 'school' && (
              <Text /*  align={'center'} */ style={{ margin: '20px 0' }} size={14}>
                Se vuoi iscriverti come Istituto Comprensivo, inserisci nel Form di registrazione
                qui sotto, il Codice Meccanografico del tuo Istituto Comprensivo. <br /> Se sei una
                scuola, inserisci nel Form di registrazione qui sotto, il Codice Meccanografico del
                tuo plesso.
              </Text>
            )}
            <div>{inputs.slice(1)}</div>
          </InputsWrapper>
        </AuthContentWrapper>
        {/*
				<ProgressDots>
          <div className={`${step >= 1 ? 'active' : null}`} />
					<div className={`${step >= 2 ? 'active' : null}`} />
					<div className={`${step === 3 ? 'active' : null}`} />
        </ProgressDots>
				*/}

        <Flex justify='space-between' style={{ marginTop: '-20px' }}>
          <CustomButton active upper bold type='button' width='48%'>
            <Link to='/auth/login'>Chiudi</Link>
          </CustomButton>
          <Button
            white
            active
            upper
            bold
            width='48%'
            loading={loadingSignup}
            style={{ justifyContent: 'center' }}
          >
            {t('auth.registerlabel')}
          </Button>
        </Flex>
      </form>
      <Modal alert show={signupModal} close={signupModalHandler}>
        {signupSuccessMessage ||
          "Ti abbiamo mandato un'email, clicca sul link per confermare la registrazione, controlla anche la casella spam."}
      </Modal>
      <Modal show={showHint} close={() => setShowHint()}>
        <Text>{showHint}</Text>
      </Modal>
      <Modal alert show={showModal} close={modalHandler} type='error'>
        <ErrorInModal error={error} fromTranslation={typeof error === 'number' ? true : false} />
      </Modal>
    </Container>
  );
};

const InputsWrapper = styled.div`
  overflow: auto;
`;

/* const ProgressDots = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;

  & > div {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #dce4e8;

    &.active {
      background-color: ${props => props.theme.primary};
    }
  }
`; */

const mapStateToProps = state => {
  return {
    loadingSignup: state.auth.loadingSignup,
    signup: state.auth.signup,
    signupSuccessMessage: state.auth.signupSuccessMessage,
    error: state.auth.errorSignup,
    lang: state.app.config.lang,
    own_login_adult: state.app.config.ownlogin.adult,
    fields: state.app.config.ownlogin.fields,
    code: state.app.config.code,
    codefield: state.app.config.ownlogin.code,
    extrafields: state.app.config.ownlogin.extrafields,
    notes: state.app.config.ownlogin.notes,
    gdpr: state.app.config.ownlogin.gdpr,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUpInit: data => dispatch(actionCreators.signUpInit(data)),
    clearError: () => dispatch(actionCreators.signupClearError()),
  };
};

RegisterForm.propTypes = {
  signUpInit: PropTypes.func,
  loadingSignup: PropTypes.bool,
  signup: PropTypes.bool,
  clearError: PropTypes.func,
  error: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
